import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import playace from "../../../assets/logo_white.png";
import bgImg from "../../../assets/Frame 619.png";
import whatsappImg from "../../../assets/whatsapp.png";
import {
  getErrorMessage,
  regex,
  showSuccessToast,
  showErrorToast,
} from "../../../utils/utils";
import useForm from "../../../utils/useForm";
import { contactUs } from "../../../api/requests";

const initialValues = {
  name: {
    value: "",
    patternErrorMessage: "Name is not valid",
    regex: regex.name,
    isRequired: true,
    isErrored: false,
    errorMessage: "",
  },
  phone: {
    value: "",
    patternErrorMessage: "Phone number is not valid",
    regex: regex.phone,
    isRequired: true,
    isErrored: false,
    errorMessage: "",
  },
  email: {
    value: "",
    patternErrorMessage: "Email is not valid",
    regex: regex.email,
    isRequired: true,
    isErrored: false,
    errorMessage: "",
  },
  message: {
    value: "",
    isRequired: true,
    isErrored: false,
    errorMessage: "",
  },
};

const ContactUsModal = ({ show, handleClose }) => {
  const { form, handleFormChange, handleSubmit, updateForm } =
    useForm(initialValues);

  const handleSendMessage = async (data) => {
    try {
      const response = await contactUs(data);
      if (response.status === "success") {
        showSuccessToast(
          response?.message ?? "Your account is created successfully"
        );
        handleClose();
        updateForm(initialValues);
      } else {
        showErrorToast(response?.message ?? "Something went wrong");
      }
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="max80">
      <Modal.Body>
        <div className="contactGrid">
          <div className="bgImgContact">
            <div className="contactInfoAb">
              <div className="logoDiv">
                <img src={playace} alt="playace-logo" width="130" />
              </div>

              <p>
                For the Love of House Parties - Meet Your New Offline Social
                Network.
              </p>

              <div className="social d-flex align-items-center align-content-center">
                <a
                  href="https://www.youtube.com/@playacehouseparties2121"
                  className="d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-brand-youtube"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="" />
                    <path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" />
                    <path d="M10 9l5 3l-5 3z" />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/playaceapp/"
                  className="d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/playaceapp"
                  className="d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-brand-twitter"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c0 -.249 1.51 -2.772 1.818 -4.013z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/playace.co/"
                  className="d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                    <path d="M16.5 7.5l0 .01" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              handleSubmit(e, handleSendMessage);
            }}
            className="formContContact"
          >
            <div className="basicText">
              <div className="upperCl">
                <h3>Contact Us</h3>

                <div onClick={handleClose} className="cursor">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </div>
              </div>
              <p>
                Let us know how we can help, we will get back to you as soon as
                we can
              </p>
            </div>
            <div>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your Name"
                value={form.name.value}
                onChange={handleFormChange}
                className="form-control mb-2"
              />
              {getErrorMessage(form, "name")}
            </div>

            <div className="customPadW">
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter WhatsApp Mobile Number"
                value={form.phone.value}
                onChange={handleFormChange}
                className="form-control mb-2"
              />
              <img src={whatsappImg} alt="" />
              {getErrorMessage(form, "phone")}
            </div>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={form.email.value}
                onChange={handleFormChange}
                className="form-control mb-2"
              />
              {getErrorMessage(form, "email")}
            </div>
            <div>
              <textarea
                rows={3}
                id="message"
                name="message"
                placeholder="Enter your message"
                value={form.message.value}
                onChange={handleFormChange}
                className="form-control mb-2"
              />
              {getErrorMessage(form, "message")}
            </div>

            <button className="btn btn-primary">Send Message</button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactUsModal;
